import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../models/config';
import { Token } from '../models/api';
import { Day, Order } from '../models/order';
import { environment } from 'src/environments/environment';
import { Attraction } from '../models/attraction';
import { TicketExport } from '../models/ticket-export';
import { User, UserPassword } from '../models/user';
import { Product } from '../models/product';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  login(loginForm: { email: string; password: string }) {
    return this.http.post<Token>(`${environment.apiUrl}/cms/login`, loginForm);
  }

  getPurchase(orderId: string) {
    return this.http.get<Order>(
      `${environment.apiUrl}/cms/purchases/${orderId}`
    );
  }

  getReceipt(orderId: string) {
    return this.http.get<{ receipt: string }>(
      `${environment.apiUrl}/cms/purchases/${orderId}/receipt`
    );
  }

  getPurchases(
    attractionId: number,
    params: { dateFrom: string; dateTo: string } | null
  ) {
    return this.http.get<Day[]>(
      `${environment.apiUrl}/cms/purchases?attractionId=${attractionId}`,
      {
        params: params ?? {},
      }
    );
  }

  getPurchasesByDay(attractionId: number, day: string) {
    return this.http.get<Order[]>(
      `${environment.apiUrl}/cms/purchases?attractionId=${attractionId}&day=${day}`
    );
  }

  getConfig(attractionId: number) {
    return this.http.get<Config>(
      `${environment.apiUrl}/cms/config?attractionId=${attractionId}`
    );
  }

  setConfig(config: any) {
    return this.http.post(`${environment.apiUrl}/cms/config`, config);
  }

  getAttractions() {
    return this.http.get<Attraction[]>(`${environment.apiUrl}/cms/attractions`);
  }

  getTickets(params: {
    amount: number;
    productIds: Product;
    validFrom: string;
    validTo: string;
  }) {
    console.log(params)
    return this.http.get(`${environment.apiUrl}/cms/vouchers`, {
      params: {
        amount: params.amount,
        productId: params.productIds.id,
        validFrom: new Date(params.validFrom).toISOString().split("T")[0],
        validTo: new Date(params.validTo).toISOString().split("T")[0]
      },
      responseType: 'arraybuffer',
    });
  }

  //PRODUCTS
  getProducts(attractionId: number) {
    return this.http.get(`${environment.apiUrl}/cms/products?attractionId=${attractionId}`)
  }

  patchProducts(attractionId: number, products: Product) {
    return this.http.patch(`${environment.apiUrl}/cms/products?attractionId=${attractionId}`, products)
  }

  //PROFILE
  getProfile() {
    return this.http.get<User>(`${environment.apiUrl}/cms/profile`);
  }

  patchProfile(data: User) {
    return this.http.patch(`${environment.apiUrl}/cms/profile`, data);
  }

  patchProfilePassword(data: UserPassword) {
    return this.http.patch(`${environment.apiUrl}/cms/profile/password`, data);
  }
}
