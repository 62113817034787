import { Component } from '@angular/core';
import {
  FormBuilder,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private router: Router,
    private messageService: MessageService
  ) {
    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  submit() {
    //DEBUG
    this.router.navigate(['/private', 'dashboard'], { replaceUrl: true });

    if (this.loginForm.valid) {
      this.api.login(this.loginForm.value).subscribe({
        next: (res) => {
          if (res && res?.token) {
            localStorage.setItem('access_token', res.token);
            this.router.navigate(['/private', 'dashboard'], {
              replaceUrl: true,
            });
          }
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'Fehler',
            detail:
              'Während des Vorgangs ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
          });
        },
      });
    }
  }
}
